import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Table, message, TimePicker, DatePicker, Select, Tag, Divider,Radio, RadioChangeEvent} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/es/form/Form';
import Canteens from '../../../entity/Canteens';
import { useNavigate } from 'react-router';
import User from '../../../entity/User';
import { ApiCanteens } from '../../../internet/ApiCanteens';
import ApiUser from '../../../internet/ApiUser';
import { PermissionUtils } from '../../../tool/PermissionUtils ';
import dayjs, { Dayjs } from 'dayjs';
import { ApiConsumptionPeriod } from '../../../internet/ApiConsumptionPeriod';
import ConsumptionPeriod from '../../../entity/ConsumptionPeriod ';
import {dineType} from '../../../entity/ConsumptionPeriod '

const { Search } = Input;
const CanteenManagement: React.FC = () => {
  const [canteenData, setCanteenData] = useState<Canteens[]>([]);
  const [originalData, setOriginalData] = useState<Canteens[]>([]);
  const [userDataState, setUserData] = useState<User[]>([]);
  const [currentCanteen, setCurrentCanteen] = useState<Canteens | null>(null);
  const [idToDataMap, setIdToDataMap] = useState<Record<number, number | undefined>>({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm<Canteens>();
  const navigate = useNavigate();
  const uid = localStorage.getItem("userId");
  const apiCanteen = new ApiCanteens(message, navigate);
  const user = localStorage.getItem("user") as string;
  const apiUser = new ApiUser(message, navigate);
  const add = PermissionUtils.hasPermission("canteen:add");
  const [consumptionPeriods, setConsumptionPeriods] = useState<ConsumptionPeriod[]>([]);
  const [currentPeriod, setCurrentPeriod] = useState<ConsumptionPeriod | null>(null);
  const [isPeriodModalOpen, setIsPeriodModalOpen] = useState(false);
  const [periodForm] = useForm();
  const [selectedDineType, setSelectedDineType] =useState<number>(0)
  const apiConsumptionPeriod=new ApiConsumptionPeriod(message,navigate)
  const getCanteensUser = (data: Canteens[]) => {
    const ids = data.map(i => i.id); // 确保 map 返回 i.id
    ids.forEach(id => {
      apiCanteen.getUser(id, { exit: 0, successHandler: data => {
        if (data) {
          setIdToDataMap(prevMap => ({
            ...prevMap,
            [id]: data.id,
          }));
        }
      }});
    });
  };

  const getCanteens = () => {
    const newUser = JSON.parse(user) as User;
    const groupId = newUser.group?.id;
    if (add) {
      if (groupId) {
        apiCanteen.getById(groupId, { exit: 0, successHandler: data => {
          if (data) {
            setCanteenData(data);
            setOriginalData(data);
            getCanteensUser(data);
          }
        }});
      }
    } else {
      if (uid) {
        apiCanteen.getByUId(Number(uid), { exit: 0, successHandler: data => {
          if (data) {
            setCanteenData([data]);
          }
        }});
      }
    }
  };

  useEffect(() => {
    getCanteens();
  }, []);

  const showModal = (canteen?: Canteens) => {
    setCurrentCanteen(canteen || null);
    setIsModalOpen(true);
    if (canteen) {
      form.setFieldsValue(canteen);
    } else {
      form.resetFields();
    }
  };

  const handleOk = () => {
    const newUser = JSON.parse(user) as User;
    const groupId = newUser.group?.id;
    form.validateFields().then(data => {
      if (currentCanteen) {
        const updateCanteen = new Canteens(currentCanteen.id, data.name, data.location, data.description, currentCanteen.groupId);
        apiCanteen.update(updateCanteen, { exit: 0, successHandler: () => {
          getCanteens();
          setIsModalOpen(false);
          message.success('食堂已更新');
        }});
      } else {
        // 添加新食堂
        if (groupId) {
          const newCanteen = new Canteens(0, data.name, data.location, data.description, groupId);
          apiCanteen.add(newCanteen, { exit: 0, successHandler: () => {
            getCanteens();
            setIsModalOpen(false);
            message.success('食堂已更新');
          }});
        }
      }
      setIsModalOpen(false);
    }).catch(() => {});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteCanteen = (id: number) => {
    apiCanteen.delete(id, { exit: 0, successHandler: () => {
      setIsModalOpen(false);
      getCanteens();
      message.success('食堂已更新');
    }});
  };

  const getUserList = () => {
    if (user) {
      const newUser = JSON.parse(user) as User;
      const groupId = newUser.group?.id;
      if (groupId) {
        apiUser.getById(groupId, { exit: 0, successHandler: (data) => {
          if (data) {
            setUserData(data.filter(i => i.id !== Number(uid)));
          }
        }});
      }
    }
  };
const getconsumptionPeriod=()=>{
  if(canteenData[0]?.id){
    apiConsumptionPeriod.get(canteenData[0].id,{exit:0,successHandler:data=>{
      console.log(data);
      if(data){
        setConsumptionPeriods(data)
      }
    }})
  }
}
useEffect(() => {
  getconsumptionPeriod()
}, [canteenData]);
  useEffect(() => {
    if (add) {
      getUserList();
    }
  }, []);

  const optinos = userDataState.filter(i => i.role?.code === "defaultCanteenKeeper").map(user => {
    return {
      label: user.name,
      value: user.id
    };
  });

  const onChange = (canteensId: number, id?: number) => {
    apiCanteen.addUser(canteensId, id ?? undefined, { exit: 0, successHandler: data => {
      setIdToDataMap(prevMap => ({
        ...prevMap,
        [canteensId]: id ?? undefined,
      }));
      message.success("绑定成功");
      getCanteens();
    }});
  };

  const showPeriodModal = (period?: ConsumptionPeriod) => {
    setCurrentPeriod(period || null);
    setIsPeriodModalOpen(true);
    if (period) {
      periodForm.setFieldsValue({
        ...period,
        mealTime: dayjs(period.mealTime),
        unsubscribeTimeSub:dayjs(period.unsubscribeTimeSub),
      });
    } else {
      periodForm.resetFields();
    }
  };
  const convertToUTC = (date: Dayjs): string => {
    return date.format("YYYY-MM-DD HH:mm");
  };
  const handlePeriodOk = () => {
    periodForm.validateFields().then(data => {
      const mealTimeUTC = convertToUTC(data.mealTime);
      const unsubscribeTimeSub = convertToUTC(data.unsubscribeTimeSub);
      if (currentPeriod) {
        const updatedPeriod: ConsumptionPeriod = {
          ...currentPeriod,
          type: data.name,
          mealTime: mealTimeUTC,
          unsubscribeTime: data.unsubscribeTime,
          unsubscribeTimeSub:unsubscribeTimeSub,
          unsubscribeTimePeriod:data.unsubscribeTimePeriod
        };
        apiConsumptionPeriod.update(updatedPeriod,{exit:0,successHandler:()=>{
          getconsumptionPeriod()
          message.success("消费时间段已更新")
         setIsPeriodModalOpen(false);
         },otherHandler(code, msg) {
           return false
         },})
      } else {
        if(canteenData[0]?.id){
          const newPeriod: ConsumptionPeriod = {
            id: 0,
            description: data.description,
            canteenId: canteenData[0]?.id ,
            type: data.type,
            mealTime: mealTimeUTC,
            unsubscribeTime: data.unsubscribeTime,
            unsubscribeTimeSub:unsubscribeTimeSub,
            unsubscribeTimePeriod:data.unsubscribeTimePeriod
          }; 
               apiConsumptionPeriod.add(newPeriod,{exit:0,successHandler:()=>{
                getconsumptionPeriod()
                message.success("消费时间段已更新")
               setIsPeriodModalOpen(false);
               },otherHandler(code, msg) {
                 return false
               },})
              
        }
       
      }
    }).catch(() => {});
  };

  const handlePeriodCancel = () => {
    setIsPeriodModalOpen(false);
  };

  const deletePeriod = (id: number) => {
    apiConsumptionPeriod.delete(id,{exit:0,successHandler:data=>{
      getconsumptionPeriod()
      message.success("消费时间段已更新")
    },otherHandler(code, msg) {
      return false
    },})
  };

  const columns: ColumnsType<Canteens> = [
    {
      title: '食堂名称',
      dataIndex: 'name',
      key: 'name',
      width: 150,
      ellipsis: true
    },
    {
      title: '食堂位置',
      dataIndex: 'location',
      key: 'location',
      width: 150,
      ellipsis: true
    },
    {
      title: '描述',
      dataIndex: 'description',
      key: 'description',
      width: 100,
      ellipsis: true
    },
    {
      title: '操作',
      key: 'action',
      ellipsis: true,
      width: 200,
      render: (_, record) => {
        return <div>
          <Button type="primary" onClick={() => showModal(record)} style={{ marginRight: 30 }}>修改</Button>
          {
            add ? <Popconfirm title="确认删除该食堂?" onConfirm={() => deleteCanteen(record.id)}>
              <Button type="default" style={{ marginRight: 30 }}>删除</Button>
            </Popconfirm> : <></>
          }
          {
            add ? <Select allowClear value={idToDataMap[record.id]} style={{ width: 150 }} onChange={(id) => onChange(record.id, id)} options={optinos} >分配人员</Select> : <></>
          }
        </div>
      }
    }
  ];

  const periodColumns: ColumnsType<ConsumptionPeriod> = [
    {
      title: '名称',
      dataIndex: 'description',
      key: 'description',
      width: 150,
      ellipsis: true
    },
    {
      title: '下单时间',
      dataIndex: 'mealTime',
      key: 'mealTime',
      render: (text) => <Tag color="blue">{dayjs(text).format('HH:mm')}</Tag>,
      width: 150,
      ellipsis: true
    },
    {
      title: '可下单时间段',
      dataIndex: 'unsubscribeTime',
      key: 'unsubscribeTime',
      render: (text) => <Tag color="red">{text}小时</Tag>,
      width: 150,
      ellipsis: true
    },
    {
      title: '退订时间',
      dataIndex: 'unsubscribeTimeSub',
      key: 'unsubscribeTimeSub',
      render: (text) => <Tag color="blue">{dayjs(text).format('HH:mm')}</Tag>,
      width: 150,
      ellipsis: true
    },
    {
      title: '可退订时间段',
      dataIndex: 'unsubscribeTimePeriod',
      key: 'unsubscribeTimePeriod',
      render: (text) => <Tag color="red">{text}小时</Tag>,
      width: 150,
      ellipsis: true
    },
    {
      title: '操作',
      key: 'action',
      ellipsis: true,
      width: 200,
      render: (_, record) => {
        return <div>
          <Button type="primary" onClick={() => showPeriodModal(record)} style={{ marginRight: 30 }}>修改</Button>
          <Popconfirm title="确认删除该时间段?" onConfirm={() => deletePeriod(record.id)}>
            <Button type="default" style={{ marginRight: 30 }}>删除</Button>
          </Popconfirm>
        </div>
      }
    }
  ];

  // 就餐类型
  const radioOptions = () => {
    return Object.values(dineType).map((item) => {
      if(typeof item === 'number'){
        return (
          <Radio key={item} value={item}>
            {dineType[item]}
          </Radio>
        );
      }
    })
    
  }

  return (
    <div style={{ padding: 20 }}>
      {
        add ? <Button type="primary" onClick={() => showModal()} style={{ marginBottom: 20 }}>添加食堂</Button> : <></>
      }
      {
        add ? <Search style={{ width: 300, marginBottom: 20, float: "right" }} placeholder="搜索食堂"
          onSearch={value => {
            if (value === "") {
              setCanteenData(originalData);
            } else {
              const filteredData = originalData.filter(canteen => canteen.name?.includes(value));
              setCanteenData(filteredData);
            }
          }}
          enterButton /> : <></>
      }
      <Table size="small" columns={columns} dataSource={canteenData} rowKey="id" pagination={{ pageSize: 5 }} />
      <Modal title={currentCanteen ? "修改食堂" : "添加食堂"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Form.Item name="name" label="食堂名称" rules={[{ required: true, message: '请输入食堂名称' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="location" label="食堂位置">
            <Input />
          </Form.Item>
          <Form.Item name="description" label="描述">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      {
        !add?
      <Button type="primary" style={{marginBottom:20}} onClick={() => showPeriodModal()}>添加消费时间段</Button>
      :<></>
      }
      {
       !add&&consumptionPeriods.length?
        <div>
      <Divider>消费时间段</Divider>
      <Table size="small" columns={periodColumns} dataSource={consumptionPeriods} rowKey="id" pagination={{ pageSize: 5 }} />
      </div>
       :<></>
      }
      <Modal title={currentPeriod ? "修改消费时间段" : "添加消费时间段"} open={isPeriodModalOpen} onOk={handlePeriodOk} onCancel={handlePeriodCancel}>
        <Form form={periodForm} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item name="type" label="就餐类型" rules={[{ required: true, message: '就餐类型' }]}>
          <Radio.Group
              onChange={(e: RadioChangeEvent) => {
                setSelectedDineType(e.target.value)
                // console.log('value',e.target.value);
                
              }}
              value={selectedDineType}
            >
              {radioOptions()}
            </Radio.Group>
          </Form.Item>
          <Form.Item name="description" label="名称" rules={[{ required: true, message: '请输入名称' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mealTime" label="下单时间" rules={[{ required: true, message: '下单时间' }]}>
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item name="unsubscribeTime" label="可下单时间段" rules={[{ required: true, message: '下单时间段' }]}>
            <Input type="number" suffix="小时"/>
          </Form.Item>
          <Form.Item name="unsubscribeTimeSub" label="退订时间" rules={[{ required: true, message: '退订时间' }]}>
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item name="unsubscribeTimePeriod" label="可退订时间段" rules={[{ required: true, message: '退订时间段' }]}>
            <Input type="number" suffix="小时"/>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CanteenManagement;
