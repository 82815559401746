import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import Permission from "../entity/Permission"
import Role from "../entity/Role";
type MyMessageType = typeof message;
class ApiRole {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("roles", message, navigate)
  }
  add(roles:Role, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(roles)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  addPermissions(roleId:number,permissions:Permission[], resultParam?: ResultParam<void>) {
    const a={
      permissions:permissions,
      roleId:roleId
    }
    const permissionList=JSON.stringify(a)
    this.internetTool.ft(
      "/batch",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
    )
  }
  deletePermissions(roleId:number,permissions:Permission[], resultParam?: ResultParam<void>) {
    const a={
      permissions:permissions,
      roleId:roleId
    }
    const permissionList=JSON.stringify(a)
    this.internetTool.ft(
      "/delete/permission",
      {
        body:permissionList ,
        method: "DELETE"
      },
      resultParam,
    )
  }
  getAll(resultParam?: ResultParam<Permission[]>) {
    this.internetTool.ft(
      `/all`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getById(uid:number,resultParam?: ResultParam<Role[]>) {
    this.internetTool.ft(
      `/user/${uid}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  update(roles:Role, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(roles)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
}
export default ApiRole