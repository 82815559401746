import { Button, Checkbox, Form, Input, message, } from "antd";
import React, { useState } from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Module from './LoginPage.module.scss'
import { useLocation, useNavigate } from "react-router";
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom";
import ApiOpen from "../../internet/ApiOpen";
import ApiUser from "../../internet/ApiUser";
import ApiMenu from "../../internet/ApiMenu";
import Permission from "../../entity/Permission";
import { PermissionUtils } from "../../tool/PermissionUtils ";

const LoginPage: React.FC = () => {
  const passReg = new RegExp(/^(\w){6,20}$/)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const state = useLocation().state
  const disPatch = useDispatch()
  const apiOpen = new ApiOpen(message, navigate)
  const apiUser = new ApiUser(message, navigate)
  const [menuData, setMenuData] = useState<Permission[]>([]);
  
const apiMenuen= new ApiMenu(message,navigate)
  const loginMethod = () => {
    form.validateFields()
      .then(value => {
        apiOpen.login(value.username, value.password, {
          exit: 0,
          successHandler: user => {
            if(user?.token){
              localStorage.setItem("satoken",user?.token)
              localStorage.setItem("userId",user?.id.toString())
              localStorage.setItem("user",JSON.stringify(user))
              apiMenuen.getAll({successHandler:data=>{
                if(data){
                  PermissionUtils.sortAndStorePermissions(data)
                  window.location.href = "/";
                  let path = state?.from?.pathname ?? "/"
                  if (path === "/logout") {
                    path = "/"
                  }
                  navigate(path, { replace: true })
                }
              }})
            }
          }, otherHandler: ((code, msg) => {
            return false
          })
        })
      })
      .catch(() => {
      });
  }
  return (
    <div className={Module.root}>
      <Form form={form} className={Module.from} onFinish={loginMethod}>
        <h1 className={Module.h1} >用户登录</h1>
        <Form.Item name="username"
          rules={[{ required: true, message: '请输入账号' }]}
        >
          <Input size="large" allowClear prefix={<UserOutlined />} placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item name="password"
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password size="large" prefix={<LockOutlined />} placeholder="请输入密码" />
        </Form.Item>
        <Form.Item>
          <div className={Module.btns}>
            <Button className={Module.btn} size="large" type="primary" htmlType="submit">
              登录
            </Button>
            <Button className={Module.btn} size="large" >
              取消
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}
export default LoginPage






