import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import Dish from "../entity/Dish";
import ConsumptionPeriod from "../entity/ConsumptionPeriod ";
type MyMessageType = typeof message;
class ApiConsumptionPeriod {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("consumptionPeriod", message, navigate)
  }
  add(consumptionPeriod :ConsumptionPeriod, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(consumptionPeriod)
    console.log(permissionList);
    
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  update(consumptionPeriod :ConsumptionPeriod , resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(consumptionPeriod)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  get(canteenId:number,resultParam?: ResultParam<ConsumptionPeriod[]>) {
    this.internetTool.ft(
      `/all/${canteenId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
}
export { ApiConsumptionPeriod  }