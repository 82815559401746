import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import Dish from "../entity/Dish";
import ConsumptionPeriod from "../entity/ConsumptionPeriod ";
import Cookbook from "../entity/Cookbook";
type MyMessageType = typeof message;
class ApiCookbook {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("cookbook", message, navigate)
  }
  add(cookbook :Cookbook, resultParam?: ResultParam<void>) {
    const cookbooks=JSON.stringify(cookbook)
    this.internetTool.ft(
      "",
      {
        body:cookbooks ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  update(cookbook :Cookbook , resultParam?: ResultParam<void>) {
    const cookbooks=JSON.stringify(cookbook)
    this.internetTool.ft(
      "",
      {
        body: cookbooks,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  type(type:number,canteen:number,resultParam?:ResultParam<Array<Cookbook>>){
    this.internetTool.ft(
      `/type?type=${type}&canteenId=${canteen}`,
      {
        method:"GET"
      },
      resultParam
    )
  }
  get(canteenId:number,page:number,size:number,resultParam?: ResultParam<any[]>) {
    const params = new URLSearchParams({
      canteenId: canteenId.toString(),
      page: page.toString(),
      size: size.toString()
    });
    this.internetTool.ft(
      `?${params.toString()}`,
      {
        method: "GET",
      },
      resultParam
    )
  }
}
export { ApiCookbook  }