import LoginFrom from "./page/loginFrom/LoginFrom";
import Root from "./page/mainPage/Root";
import ExpertType from "./page/mainPage/expertType/ExpertType";
import FinishOderListPage from "./page/mainPage/oderList/FinishOderListPage";
import { Navigate, useNavigate } from "react-router";
import GroupInfoPage from "./page/mainPage/group/GroupInfoPage";
import UserListPage from "./page/mainPage/userList/UserListPage";
import UserInfo from "./page/mainPage/userInfo/UserInfo";
import Foodlist from "./page/mainPage/expert/Foodlist";
import OrderDetailsPage from "./page/mainPage/oderList/OrderDetailsPage";
import UnFinishOderListPage from "./page/mainPage/oderList/UnFinishOderListPage";
import RoleListPage from "./page/mainPage/rolePage/roleListPage";
import MenuListPage from "./page/mainPage/rolePage/menuListPage";
import Permission from "./entity/Permission";
import CanteenManagement from "./page/mainPage/group/CanteenManagement";
import RecipePage from "./page/mainPage/recipePage/RecipePage";

const satoken=localStorage.getItem("satoken")
const storedRoutes = localStorage.getItem("routes");
const dynamicRoutes = storedRoutes ? JSON.parse(storedRoutes) : [];
const allRoutes =  [
  {
    index: true,
    element: <Foodlist />,
  },
  {
    path: "group",
    element: <GroupInfoPage />,
  },
  {
    path: "canteenManagement",
    element: <CanteenManagement />,
  },
  {
    path: "recipePage",
    element: <RecipePage />,
  },
  {
    path: "userList",
    element: <UserListPage />,
  },
  {
    path: "userInfo",
    element: <UserInfo />,
  },
  {
    path: "OderListPage",
    children: [
      {
        path: "finishOderList",
        children: [
          {
            index: true,
            element: <FinishOderListPage />,
          },
          {
            path: "finishOderList",
            element: <FinishOderListPage />,
          },
          {
            path: ":oderId",
            element: <OrderDetailsPage />,
          }
        ]
      },
      {
        path: "unFinishOderListPage",
        children: [
          {
            index: true,
            element: <UnFinishOderListPage />,
          },
          {
            path: "unFinishOderListPage",
            element: <UnFinishOderListPage />,
          },
          {
            path: ":oderId",
            element: <OrderDetailsPage />,
          }
        ]
      },
    ]
  },
  {
    path: "foodList",
    element: <Foodlist />,
  },
  {
    path: "roleList",
    children: [
      {
        path: "menuListPage",
        element: <MenuListPage />,
      },
      {
        path: "roleListPage",
        element: <RoleListPage />,
      },
    ]
  },

]
const extractPaths = (permissions: Permission[]): string[] => {
  let paths: string[] = [];

  const extract = (perms: Permission[]) => {
    perms.forEach(perm => {
      if (perm.path) {
        paths.push(perm.path);
      }
      if (perm.children && perm.children.length > 0) {
        extract(perm.children);
      }
    });
  };

  extract(permissions);
  return paths;
};

const filterRoutes = (permissions: Permission[], allRoutes: any[]): any[] => {
  const paths = extractPaths(permissions);

  const filterRouteChildren = (children: any[]): any[] => {
    return children
      .filter(child => paths.includes(child.path) || child.index === true)
      .map(child => ({
        ...child,
        children: child.children ? filterRouteChildren(child.children) : undefined,
      }));
  };

  return allRoutes
    .filter(route => paths.includes(route.path) || route.index === true)
    .map(route => ({
      ...route,
      children: route.children ? filterRouteChildren(route.children) : undefined,
    }));
};

const newRouter=filterRoutes(dynamicRoutes,allRoutes)

const getRoutes = () => {
  return [
    {
      path: "/login",
      element: <LoginFrom />,
    },
    {
      path: "/",
      element: satoken ? <Root /> : <Navigate to="/login" replace />,
      children: [ {
        index: true,
        element: <Foodlist />},
      ,...newRouter, {
        path: "userInfo",
        element: <UserInfo />,
      },]
    },
  ]  
};


export default getRoutes;
