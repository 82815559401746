import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import MenuData from "../entity/MenuData";
import User from "../entity/User";
type MyMessageType = typeof message;
class ApiOpen {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("users", message, navigate)
  }
  login(username: string, password: string, resultParam?: ResultParam<User>) {
    let formData = new FormData()
    formData.append("username", username)
    formData.append("password", password)
    this.internetTool.ft(
      `/login`,
      {
        body: formData,
        method: "POST"
      },
      resultParam,
    )
  }
  signUp(mobilePhone: string, verificationCode: string, password: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("mobilePhone", mobilePhone)
    formData.append("verificationCode", verificationCode)
    formData.append("password", password)
    this.internetTool.ft(
      "/register",
      {
        body: formData,
        method: "POST",
        credentials: 'include'
      },
      resultParam
    )
  }
  sendCode(mobilePhone: string, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/auth_code?mobilePhone=${mobilePhone}`,
      {
        method: "GET",
      },
      resultParam
    )
  }
  signUpWithCompany(user: User, password: string, company_name: string, auth_code: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("password", password)
    formData.append("company_name", company_name)
    formData.append("auth_code", auth_code)
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    this.internetTool.ft(
      "/sign_up_with_company",
      {
        body: formData,
        method: "POST",
      },
      resultParam
    )
  }
  signUpByCompany(user: User, password: string, company_code: string, auth_code: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("password", password)
    formData.append("company_code", company_code)
    formData.append("auth_code", auth_code)
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    this.internetTool.ft(
      "/sign_up_by_company",
      {
        body: formData,
        method: "POST",
      },
      resultParam
    )
  }
}
export default ApiOpen