import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Radio, Select, Switch, Table, Tag, Tree, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/es/form/Form';
import Permission from '../../../entity/Permission';
import ApiMenu from '../../../internet/ApiMenu';
import { useNavigate } from 'react-router';
import TreeSelect, { TreeNode } from 'antd/es/tree-select';
import { PermissionUtils } from '../../../tool/PermissionUtils ';

const { TextArea } = Input;


const MenuListPage: React.FC = () => {
  const [menuData, setMenuData] = useState<Permission[]>([]);
  const [currentMenu, setCurrentMenu] = useState<Permission | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm<Permission>();
  const [menuType, setMenuType] = useState<number>();
  const [value, setValue] = useState<string>();
  const navigate=useNavigate()
  const apiMenuen= new ApiMenu(message,navigate)
  const showModal = (menu?: Permission) => {
    setCurrentMenu(menu || null);
    setIsModalOpen(true);
    if (menu) {
      form.setFieldsValue(menu);
      setMenuType(menu.type);
    } else {
      form.resetFields();
      setMenuType(0);
    }
  };

  const handleOk = () => {
    if(!currentMenu){
      form.validateFields().then(data => {
        const permission = new Permission(
          data.id, // id
          data.label, // label
          data.parentId, // parentId
          data.parentName, // parentName
          data.code, // code
          data.path, // path
          data.name, // name
          data.url, // url
          data.type, // type
          data.icon, // icon
          new Date(), // createTime
          new Date(), // updateTime
          data.remark, // remark
          data.orderNum, // orderNum
          0, // isDeleted
          false, // open
          [], // children
          "" // value
        );
        console.log(permission);
        apiMenuen.add(permission,{exit:0,successHandler:(data)=>{
           getPermission()
           setIsModalOpen(false);
        message.success('菜单信息已更新');
        },otherHandler(code, msg) {
          return false
        },})
        // TODO: 调用 API 更新或创建菜单
        
      }).catch(() => {});
    }else{
      form.validateFields().then(data => {
        const permission = new Permission(
          currentMenu.id, // id
          data.label, // label
          data.parentId, // parentId
          data.parentName, // parentName
          data.code, // code
          data.path, // path
          data.name, // name
          data.url, // url
          data.type, // type
          data.icon, // icon
          new Date(), // createTime
          new Date(), // updateTime
          data.remark, // remark
          data.orderNum, // orderNum
          0, // isDeleted
          false, // open
          [], // children
          "" // value
        );
        apiMenuen.update(permission,{exit:0,successHandler:(data)=>{
          setIsModalOpen(false);
          message.success('菜单信息已更新');
          getPermission()
        },otherHandler(code, msg) {
          return false
        },})
        // TODO: 调用 API 更新或创建菜单
       
      }).catch(() => {});
    }
   
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteMenu = (id: number|undefined) => {
    if(id){
      apiMenuen.delete(id,{exit:0,successHandler:()=>{
        getPermission()
        message.success("删除成功")
      },otherHandler(code, msg) {
        return false
      },})

    }
    // TODO: 调用 API 删除菜单
  };

  const columns: ColumnsType<Permission> = [
    {
      title: '权限名称',
      dataIndex: 'label',
      key: 'label',
      width: 200,
      ellipsis: true,
      render: (value) => <Tag color="blue">{value}</Tag>,
    },
    {
      title: '排序',
      dataIndex: 'order',
      key: 'order',
      width: 100,
      ellipsis: true
    },
    {
      title: '权限标识',
      dataIndex: 'code',
      key: 'code',
      width: 100,
      ellipsis: true
    },
   
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) => <Switch checked={status === 0} />,
      ellipsis: true
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 100,
      ellipsis: true,
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: '操作',
      key: 'operation',
      render: (_, record) => (
        <div>
          <Button type="link" onClick={() => showModal(record)}>修改</Button>
          <Popconfirm title="是否删除该权限" onConfirm={() => deleteMenu(record.id)}>
            <Button type="link" danger>删除</Button>
          </Popconfirm>
        </div>
      ),
      width: 200,
      ellipsis: true,
      align: "center"
    },
  ];
const getPermission=()=>{
  apiMenuen.getAll({successHandler:data=>{
    if(data){
      setMenuData(data);
      PermissionUtils.sortAndStorePermissions(data)
    }
  }})
}
  useEffect(() => {
    // 初始化数据
    getPermission()
  }, []);
  const treeData = [
    {
      title: '全部主类目',
      value: '0',
      children: menuData.map((menu) => ({
        title: menu.label,
        value: menu.id,
        children: menu.children?.map((child) => ({
          title: child.label,
          value: child.id,
        })),
      })),
    },
  ];
  const onChange = (newValue: string) => {
    setValue(newValue);
  };
  return (
    <div style={{ width: "100%", height: "100%", padding: "1%" }}>
      <div style={{ marginBottom: 16 }}>
        <Button type="primary" onClick={() => showModal()}>新增权限</Button>
      </div>
      <div style={{ width: "100%", height: "100%", overflowY:"auto" }}>
      <Table
        columns={columns}
        size="small"
        rowKey="id"
        pagination={{ pageSize: 10 }}
        dataSource={menuData}
        expandable={{ childrenColumnName: 'children', indentSize: 20 }}
      />
      </div>
      <Modal title={currentMenu ? "修改菜单" : "新增菜单"} open={isModalOpen} okText={currentMenu ? "修改" : "新增"} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item
            label="权限名称"
            name="label"
            rules={[{ required: true, message: '请输入权限名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="上级菜单"
            name="parentId"
            rules={[{ required: true, message: '请选择上级菜单' }]}
          >
           <TreeSelect
              style={{ width: '100%' }}
              value={value}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder="请选择上级菜单"
              treeDefaultExpandAll
              onChange={onChange}
              allowClear
             />
          </Form.Item>
          <Form.Item
            label="菜单类型"
            name="type"
            rules={[{ required: true, message: '请选择菜单类型' }]}
          >
            <Radio.Group onChange={(e) => setMenuType(e.target.value)}>
              <Radio value={0}>目录</Radio>
              <Radio value={1}>菜单</Radio>
              <Radio value={2}>按钮</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="菜单图标"
            name="icon"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="菜单名称"
            name="name"
            rules={[{ required: true, message: '请输入菜单名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="显示排序"
            name="orderNum"
          >
            <Input type="number" />
          </Form.Item>
            <>
              <Form.Item
                label="路由地址"
                name="path"
              >
                <Input />
              </Form.Item>
            </>
           <Form.Item
                label="权限标识"
                name="code"
                rules={[{ required: true, message: '请输入权限标识' }]}
              >
                <Input />
              </Form.Item>
          <Form.Item
            label="备注"
            name="remark"
          >
            <TextArea rows={1} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default MenuListPage;
