import Permission from "../entity/Permission"

const defaultState = {
  id: 0,
  tabs: "1",
  menus:new Permission(0,)
}

let Reducer = (state = defaultState, action: { type: string, value: number }) => {
  let newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case "update":
      newState.user = action.value
      break
    case "add":
      newState.menus = action.value
      break
    case "type":
      newState.tabs = action.value.toString()
  }
  return newState
}
export default Reducer