import Group from "./Group";
import Role from "./Role";

export default class User {
  id: number;
  username: string;
  password: string;
  name?: string;
  phone?: string;
  roleId?: number;
  status?: number;
  gender?: number;
  avatar?: string;
  createTime?: Date;
  role?:Role
  token?: string;
  group?: Group;
  isAdmin?: boolean;
  

  constructor(
    username: string,
    password: string,
    id: number,
    name?: string,
    phone?: string,
    roleId?: number,
    status?: number,
    gender?: number,
    avatar?: string,
    createTime?: Date,
    token?: string,
    role?:Role,
    group?: Group,
    isAdmin?: boolean
  ) {
    this.id = id;
    this.username = username;
    this.password = password;
    this.name = name;
    this.phone = phone;
    this.roleId = roleId;
    this.status = status;
    this.gender = gender;
    this.avatar = avatar;
    this.role = role;
    this.createTime = createTime;
    this.token = token;
    this.group = group;
    this.isAdmin = isAdmin;
  }}
