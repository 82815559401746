export default class Canteens {
    id: number;
    name: string;
    location: string;
    description: string;
    groupId: number;
  
    constructor(
      id: number,
      name: string,
      location: string,
      description: string,
      groupId: number
    ) {
      this.id = id;
      this.name = name;
      this.location = location;
      this.description = description;
      this.groupId = groupId;
    }
  }
  