import React, { useEffect, useState } from 'react';
import {
  BankOutlined,
  ApartmentOutlined,
  ToolOutlined,
  UsergroupAddOutlined,
  AppstoreAddOutlined,
  ChromeOutlined,
  ReadOutlined,
  PicCenterOutlined,
  BorderBottomOutlined,
  BorderTopOutlined,
  SlidersOutlined,
  WindowsOutlined,
  BgColorsOutlined,
  AliwangwangOutlined,
  DropboxOutlined
} from '@ant-design/icons';
import { Menu, message, } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import ApiUser from '../../internet/ApiUser';
import Permission from '../../entity/Permission';


const MenuPage: React.FC = () => {
  const [show, setShow] = useState<boolean>()
  const [selectKey, setSelectKey] = useState<string>("/")
  const [openKeys, setOpenKeys] = useState<string[]>(["project"])
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  const apiUser = new ApiUser(message, navigate)
  const storedRoutes = localStorage.getItem("routes");
  const dynamicRoutes = storedRoutes ? JSON.parse(storedRoutes) : [];
  useEffect(() => {
  }, [location])
// 根据权限生成菜单项

  const generateMenuItems = (permissions: Permission[], parentKey = ''): any[] => {
    return permissions.map(perm => {
      const currentKey = parentKey ? `${parentKey}/${perm.path}` : perm.path;
      const menuItem: any = {
        key: currentKey,
        label: perm.label,
      };
      if (perm.children && perm.children.length > 0) {
        const childItems = generateMenuItems(perm.children, currentKey).filter(Boolean);
        if (childItems.length > 0) {
          menuItem.children = childItems;
        }
      }
      // 为不同的菜单项设置图标
      switch (perm.label) {
        case '菜品管理':
          menuItem.icon = <AppstoreAddOutlined />;
          break;
        case '订单管理':
          menuItem.icon = <PicCenterOutlined />;
          break;
        case '权限管理':
          menuItem.icon = <SlidersOutlined />;
          if (!menuItem.children || menuItem.children.length === 0) {
            return null; // 如果 "权限管理" 没有子项，则不显示
          }
          break;
        case '待完成订单':
          menuItem.icon = <BorderBottomOutlined />;
          break;
          case '菜谱管理':
            menuItem.icon =<DropboxOutlined />;
            break;
        case '已完成订单':
          menuItem.icon = <BorderTopOutlined />;
          break;
        case '菜单管理':
          menuItem.icon = <WindowsOutlined />;
          break;
        case '角色管理':
          menuItem.icon = <AliwangwangOutlined />;
          break;
        case '食堂管理':
          menuItem.icon = <BgColorsOutlined />;
          break;
        case '公司管理':
          menuItem.icon = <ChromeOutlined />;
          break;
        case '用户管理':
          menuItem.icon = <UsergroupAddOutlined />;
          break;
        default:
          break;
      }
      return (perm.type === 0 || perm.type === 1) ? menuItem : null;
    }).filter(item => item !== null);
  };
  
  const menuItems = generateMenuItems(dynamicRoutes);
  useEffect(()=>{

  },[])
  function menuClick(e: { key: string }) {
    setSelectKey(e.key)
    navigate(e.key)
  }

  useEffect(() => {
    const selectKey = pathName.slice(1)
    setSelectKey(selectKey)
    if (selectKey.includes("OderListPage")) {
      setOpenKeys(["OderListPage"])
    }
  }, [pathName])


  const currentRoute = useLocation()
  const rootSubmenuKeys = ['OderList'];
  //创建合并的父导航key的数组值
  const onOpenChange = (keys: string[]) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey ?? "") === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      theme="dark"
      mode="inline"
      defaultSelectedKeys={[currentRoute.pathname]}
      selectedKeys={[selectKey]}
      openKeys={openKeys}
      onClick={menuClick}
      onOpenChange={onOpenChange}
      items={menuItems}
    />
  );
};

export default MenuPage;