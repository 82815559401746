import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import Permission from "../entity/Permission"
type MyMessageType = typeof message;
class ApiMenu {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("permissions", message, navigate)
  }
  add(permission:Permission, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(permission)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  getAll(resultParam?: ResultParam<Permission[]>) {
    this.internetTool.ft(
      `/all`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  update(permission:Permission, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(permission)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
}
export default ApiMenu