 export default class Category {
    id: number;
    name?: string;
    description?: string;
    canteenId?:number
  
    constructor(
      id: number,
      name?: string,
      description?: string,
      canteenId?:number
    ) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.canteenId = canteenId;
    }
  }