import { NavigateFunction } from "react-router-dom";
import ResultBody from "../entity/ResultBody";
import InternetTool from "../internet/Internetool";
import { ResultParam } from "./ResultParam";
import { message } from "antd";
type MessageApi = typeof message;
let isTokenExpired = false;
export class ResultHandler {
  message: MessageApi
  navigate: NavigateFunction
  constructor(message: MessageApi, navigate: NavigateFunction) {
    this.message = message;
    this.navigate = navigate;
  }
  bodyHandlerDefault<T>(
    resultParam?: ResultParam<T>
  ): (body: ResultBody<T>) => void {
    return (body: ResultBody<any>) => {
      if (body.code === InternetTool.success || body.code === "200") {
        if (resultParam?.successHandler) {
          resultParam.successHandler(body.body)
        } else {
          this.message.success("操作成功")
        }
        if (resultParam?.exit !== 0) {
          this.navigate(-(resultParam?.exit ?? 1))
        }
      } else if (body.code === "401") {
        if (!isTokenExpired) {
          isTokenExpired = true;
          this.message.error(body.message ?? "操作失败");
          this.navigate("/login");
        }
      }
      else if (body.code === "-1" || body.code === "-2" || body.code === "-3" || body.code === "-4") {
        if (!isTokenExpired) {
          isTokenExpired = true;
          this.message.error(body.message ?? "操作失败");
          this.navigate("/login");
        }
      } else {
        if (resultParam?.otherHandler) {
          if (!resultParam.otherHandler(body.code, body.message)) {
            this.message.error(body.message ?? "操作失败")
          }
        } else {
          this.message.error(body.message ?? "操作失败")
        }
      }
    }
  }
}