import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import ExpertData from "../entity/ExpertData";
import ExpertCategory from "../entity/ExpertCategory";
import Category from "../entity/Category";
type MyMessageType = typeof message;
class ApiCategory {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("dishesCategories", message, navigate)
  }
  add(dishCategories: Category, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(dishCategories)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "POST"
      },
      resultParam,
   true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("id", id.toString())
    this.internetTool.ft(
      ``,
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
  update(project: ExpertData, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("project", new Blob([JSON.stringify(project)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  get(userId:number,resultParam?: ResultParam<Category[]>) {
    this.internetTool.ft(
      `/user/${userId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
}
export default ApiCategory