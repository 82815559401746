export enum type {
  '普通菜谱'=0,
  '接待菜谱'=1
}
class Cookbook {
    id: number;
    type: number;
    week: string;
    consumeType: number;
    canteenId: number;
    creationTime: string|null;
    dishesIds:number[]
  
    constructor(
      id: number,
      type: number,
      week: string,
      consumeType: number,
      canteenId: number,
      creationTime: string|null,
      dishesIds:number[]
    ) {
      this.id = id;
      this.type = type;
      this.week = week;
      this.consumeType = consumeType;
      this.canteenId = canteenId;
      this.creationTime = creationTime;
      this.dishesIds = dishesIds;
    }
  }
  
  export default Cookbook;
  