export enum dineType {
  '普通餐'=0,
  '接待餐'=1
}

export default class ConsumptionPeriod {
    id: number;
    canteenId: number;
    type: number;
    description: string;
    mealTime: string;
    unsubscribeTime: number;
    unsubscribeTimeSub: string;
    unsubscribeTimePeriod: number;

  
    constructor(
      id: number,
      canteenId: number,
      type: number,
      description:string,
      mealTime: string,
      unsubscribeTime: number,
      unsubscribeTimeSub: string,
      unsubscribeTimePeriod: number,
    ) {
      this.id = id;
      this.canteenId = canteenId;
      this.type = type;
      this.description = description
      this.mealTime = mealTime;
      this.unsubscribeTime = unsubscribeTime;
      this.unsubscribeTimeSub = unsubscribeTimeSub;
      this.unsubscribeTimePeriod = unsubscribeTimePeriod;
    }
  }
  