import React, { useEffect, useState } from "react";
import { AliwangwangOutlined, TwitterCircleFilled, } from '@ant-design/icons';
import Module from './LoginFromPage.module.scss'
import { useDispatch } from "react-redux"
import LoginPage from "../login/LoginPage";
import RegisterPage from "../register/RegisterPage";

const LoginFromPage: React.FC = () => {
  const disPatch = useDispatch()
  const [showState, setShowState] = useState<number>(0)
  const registerPage = () => {
    setShowState(1)
  }
  const loginPage = () => {
    setShowState(0)
  }
  const show = () => {
    switch (showState) {
      case 0:
        return <LoginPage />

      case 1:
        return <RegisterPage />
      default:
        return <></>
    }
  }

  return (
    <div className={Module.root}>
      <div className={Module.home}>
        <div className={Module.brand}>
          <TwitterCircleFilled />
          报餐管理系统<span className={Module.ver}>v1.0</span>
        </div>
        {show()}
        <div className={Module.footer}>
          {
            showState === 0 ?
              <div className={Module.left} >
                {/* <AliwangwangOutlined style={{ marginRight: 5, color: " #1890FF" }} />
                <span className={Module.register} onClick={registerPage}>注册新用户</span> */}
              </div> : <div className={Module.right}>
                <AliwangwangOutlined style={{ marginRight: 5 }} />
                已有用户？
                <span className={Module.register} onClick={loginPage}>登录</span>
              </div>
          }

        </div>
      </div>
    </div>
  )
}
export default LoginFromPage






