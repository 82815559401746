import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import ExpertData from "../entity/ExpertData";
type MyMessageType = typeof message;
class ApiExpert {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("expert", message, navigate)
  }
  add(expert: ExpertData, certImage?: File[], titleImages?: File[], resultParam?: ResultParam<void>) {
    let formData = new FormData()
    certImage?.forEach(certImage => {
      if (certImage) {
        formData.append("certImage", certImage)
      }
    })
    titleImages?.forEach(titleImages => {
      if (titleImages) {
        formData.append("titleImages", titleImages)
      }
    })
    formData.append("expert", new Blob([JSON.stringify(expert)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
   batchAddExpert( file: File, resultParam?: ResultParam<void>) {
    let formData = new FormData()
   formData.append("file", file)
    this.internetTool.ft(
      "/batch",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }


  delete(expertId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("expertId", expertId.toString())
    this.internetTool.ft(
      ``,
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
  update(expert: ExpertData, certImage?: File[], titleImages?: File[], resultParam?: ResultParam<void>) {
    let formData = new FormData()
    certImage?.forEach(certImage => {
      if (certImage) {
        formData.append("certImage", certImage)
      }
    })
    titleImages?.forEach(titleImages => {
      if (titleImages) {
        formData.append("titleImages", titleImages)
      }
    })
    formData.append("expert", new Blob([JSON.stringify(expert)], { type: "application/json" }))
    this.internetTool.ft(
      "",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  get(resultParam?: ResultParam<ExpertData[]>) {
    this.internetTool.ft(
      ``,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getByid(id: number, resultParam?: ResultParam<ExpertData>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getUnfinished(isMyProject: boolean, resultParam?: ResultParam<ExpertData[]>) {
    this.internetTool.ft(
      `/unapproved?isMyProject=${isMyProject}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getAccomplish(resultParam?: ResultParam<ExpertData[]>) {
    this.internetTool.ft(
      `/accomplish`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  getByGroupId(groupId: number, resultParam?: ResultParam<ExpertData[]>) {
    this.internetTool.ft(
      `/list/${groupId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
}
export default ApiExpert