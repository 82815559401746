import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Radio, Select, Switch, Table, Tag, Tree, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import ApiRole from '../../../internet/ApiRole';
import { useNavigate } from 'react-router';
import Role from '../../../entity/Role';
import Permission from '../../../entity/Permission';
import ApiMenu from '../../../internet/ApiMenu';
import { PermissionUtils } from '../../../tool/PermissionUtils ';
import ApiAdmin from '../../../internet/ApiAdmin';



  const RoleListPage: React.FC = () => {
  const [roleData, setRoleData] = useState<Role[]>([]);
  const [menuData, setMenuData] = useState<Permission[]>([]);
  const [currentRole, setCurrentRole] = useState<Role | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm<Role>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const uid=localStorage.getItem("userId")
  const navigate=useNavigate()
  const apiRole=new ApiRole(message,navigate)
  const apiMenu=new ApiMenu(message,navigate)
  const apiAdmin=new ApiAdmin(message,navigate)
  const showModal = (role?: Role) => {
    setCurrentRole(role || null);
    setIsModalOpen(true);
    console.log('role',role);
    if (role) {
      form.setFieldsValue(role);
      if(role.permissions){
        const ids= PermissionUtils.collectLeafIds(role.permissions)
        setCheckedKeys(ids);
      } else{
        setCheckedKeys([])
      }
    } else {
      form.resetFields();
      setCheckedKeys([]);
    }
  };
  const handleOk = () => {
  if(!currentRole){
    form.validateFields().then(data => {
    const filteredMenuData = PermissionUtils.filterMenuData(menuData, checkedKeys);
    const updatedRole = new Role(0, Number(uid),data.name,data.code,data.status,new Date(),data.description,filteredMenuData);
    // TODO: 调用 API 更新或创建角色
    apiRole.add(updatedRole,{exit:0,successHandler:data=>{
      setIsModalOpen(false);
      getById()
      message.success('角色信息已更新');
    }})
   
  }).catch(() => {});
}else{
  form.validateFields().then(data => {
    const filteredMenuData = PermissionUtils.filterMenuData(menuData, checkedKeys);
    const currentPermissions=currentRole.permissions??[]
    console.log(filteredMenuData);
    const diffPermissions= PermissionUtils.diffPermissions(currentPermissions,filteredMenuData)
    const addIds=diffPermissions.added.map(i=>i.id)
    const deleteIds=diffPermissions.removed.map(i=>i.id)
    const updatedRole = new Role(currentRole.id, Number(uid),data.name,data.code,data.status,new Date(),data.description,filteredMenuData);
    // TODO: 调用 API 更新或创建角色
    console.log(diffPermissions);
    const addPermissionsPromise = diffPermissions.added.length
    ? apiRole.addPermissions(currentRole.id, diffPermissions.added, {
        exit: 0,
        successHandler: () => { getById();},
        otherHandler: (code, msg) => {
          return false;
        },
      })
    : Promise.resolve();

  const deletePermissionsPromise = diffPermissions.removed.length
    ? apiRole.deletePermissions(currentRole.id, diffPermissions.removed, {
        exit: 0,
        successHandler: () => {getById();},
        otherHandler: (code, msg) => {
          return false;
        },
      })
    : Promise.resolve();
      apiRole.update(updatedRole,{exit:0,successHandler:data=>{
        Promise.all([addPermissionsPromise, deletePermissionsPromise])
            .then(() => {
              setIsModalOpen(false);
              message.success('角色信息已更新');
            })
            .catch(error => {
              message.error('更新权限时出错');
            });

      },otherHandler(code, msg) {
        return false
      },})

  }).catch(() => {});
}
    

  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteRole = (id: number) => {
    apiRole.delete(id,{exit:0,successHandler:data=>{
      getById()
      message.success('角色信息已更新');
    }})
    // TODO: 调用 API 删除角色
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
  };

  const columns: ColumnsType<Role> = [
    {
      title: '角色编号',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      ellipsis: true
    },
    {
      title: '角色名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) => <Switch checked={status === 1} />,
      ellipsis: true
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      ellipsis: true,
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: '操作',
      key: 'operation',
      render: (_, record) => (
        <div>
           {
        PermissionUtils.hasPermission("role:update")?
        <Button type="link" onClick={() => showModal(record)}>修改</Button>
          :<></>
        }
          {
        PermissionUtils.hasPermission("role:delete")?
        <Popconfirm title="是否删除该角色" onConfirm={() => deleteRole(record.id)}>
            <Button type="link" danger>删除</Button>
          </Popconfirm>
          :<></>
        }
        </div>
      ),
      width: 200,
      ellipsis: true,
      align: "center"
    },
  ];
  const getMenuData=()=>{
    apiMenu.getAll({exit:0,successHandler:data=>{
      if(data){
        setMenuData(data)
      }
    },otherHandler(code, msg) {
      return false
    },})
  }
const getById=()=>{
  apiRole.getById(Number(uid),{exit:0,successHandler:data=>{
    if(data){
      console.log(data);
     setRoleData(data)
    }
  }})
}
  useEffect(() => {
    getMenuData()
      getById()
    // 初始化数据
  }, []);

  const renderTreeNodes = (data: Permission[]) =>
    data.map(item => {
      if (item.children) {
        return (
          <Tree.TreeNode title={item.label} key={item.id.toString()} >
            {renderTreeNodes(item.children)}
          </Tree.TreeNode>
        );
      }
      return <Tree.TreeNode title={item.label} key={item.id.toString()} />;
    });
    const generateTreeData = (menus: Permission[]): any[] => {
      return menus.map(menu => ({
        title: menu.label,
        value: menu.id,
        key: menu.id,
        children: menu.children ? generateTreeData(menu.children) : [],
      }));
    };
    
    const treeData = [
      {
        title: '全部主类目',
        value: '0',
        key: '0',
        children: generateTreeData(menuData),
      },
    ];
  return (
    <div style={{ width: "100%", height: "100%", padding: "1%" }}>
      <div style={{ marginBottom: 16 }}>
        {
        PermissionUtils.hasPermission("role:add")?
        <Button type="primary" onClick={() => showModal()}>新增角色</Button>
          :<></>
        }
      </div>
      <Table
        columns={columns}
        size="small"
        rowKey="id"
        pagination={{ pageSize: 10 }}
        dataSource={roleData}
      />
      <Modal  title={currentRole ? "修改角色" : "新增角色"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item
            label="角色名称"
            name="name"
            rules={[{ required: true, message: '请输入角色名称' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="角色描述"
            name="description"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="角色标识符"
            name="code"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="状态"
            name="status"
          >
            <Radio.Group>
              <Radio value={1}>正常</Radio>
              <Radio value={0}>停用</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="菜单权限"
            name="menuPermissions"
          >
            <div style={{backgroundColor:"#E5E6E7",padding:2,borderRadius:6}}>
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              treeData={treeData}
            />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default RoleListPage;
