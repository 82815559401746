 export default class Group {
    id: number;
    adminId:number;
    name?: string;
    description?: string;
    groupName?: string;
    status?: number;
  
    constructor(
      id: number,
      adminId:number,
      name?: string,
      description?: string,
      groupName?: string,
      status?: number
    ) {
      this.id = id;
      this.adminId = adminId;
      this.name = name;
      this.description = description;
      this.groupName = groupName;
      this.status = status;
    }
  }