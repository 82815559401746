import React, { useEffect, useState } from 'react';
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  RollbackOutlined,
} from '@ant-design/icons';
import { Layout, Button, theme, Avatar, FloatButton, message, Dropdown, MenuProps, Space, ConfigProvider, } from 'antd';
import { Outlet, useLocation, useNavigate } from 'react-router';
import MenuPage from './MenuPage';
import ApiUser from '../../internet/ApiUser';
import User from '../../entity/User';
import { ApiGroup } from '../../internet/ApiGroup';
import Module from './root.module.scss'
import ApiAdmin from '../../internet/ApiAdmin';
import ApiMenu from '../../internet/ApiMenu';
import { PermissionUtils } from '../../tool/PermissionUtils ';
const { Header, Sider, Content } = Layout;

const Root: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate()
  const location = useLocation()
  const apiUser = new ApiUser(message, navigate)
  const apiGroup = new ApiGroup(message, navigate)
  const [imageUrl, setImageUrl] = useState<string>();
  const [userInfo, setUserInfo] = useState<User>();
  const [groupName, setGroupName] = useState<string>("")
  const apiAdmin = new ApiAdmin(message, navigate)
  const userId=localStorage.getItem("userId")
  const apiMenuen= new ApiMenu(message,navigate)
  const uid=localStorage.getItem("userId")
  const onClick: MenuProps['onClick'] = ({ key }) => {
    navigate(`/${key}`)
  };

  const items: MenuProps['items'] = [
    {
      label: '个人信息',
      key: 'userInfo',
    },
    {
      label: '退出登录',
      key: 'login',
    },

  ]
    const getUser = () => {
      if(uid){
        apiUser.get(Number(uid),{
          exit: 0,
          successHandler: (user => {
            if (user) {
              setUserInfo(user)
            }
          }
          ), otherHandler: (code => {
            return true
          })
        })
      }
    }
  const getLogo = () => {
    apiGroup.selectLogo({
      exit: 0, successHandler: (data) => {
        const url = "data:zip;base64," + data?.toString()
        setImageUrl(url)
      }, otherHandler(code, msg) {
        return true
      },
    })
  }
  useEffect(() => {
    getUser()
    apiMenuen.getAll({successHandler:data=>{
      if(data){
        PermissionUtils.sortAndStorePermissions(data)
      }
    }})
  }, [location])
  return (
    <ConfigProvider
    theme={{
      components: {
        Layout: {
          siderBg: "#FFFFFF"
          /* 这里是你的组件 token */
        },
        Menu: {
          darkItemBg: "#FFFFFF",
          darkSubMenuItemBg: "#FFFFFF",
          darkItemSelectedBg: "#FA542E",
          darkItemColor: "#8F8F8F",
          darkItemHoverColor: "#232323",
          darkItemSelectedColor: "#232323"
        },
      },
    }}
    >      
    <Layout className={Module.all} >
      <Sider trigger={null} collapsible collapsed={collapsed} >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
            <Avatar src={imageUrl} />
          </div>
          <p className={Module.text} >{!collapsed ? groupName : ""}</p>
        </div>
        <MenuPage />
      </Sider>
      <Layout>
        <Header style={{ padding: 0, background: colorBgContainer, display: "flex", justifyContent: "space-between" }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <div style={{ marginRight: 50, }}>
            <Avatar style={{ marginRight: 10, }} size={"default"} src="https://api.dicebear.com/7.x/miniavs/svg?seed=1" />
            <Dropdown menu={{ items, onClick }}>
              <Space>
                {userInfo?.username}
                <DownOutlined />
              </Space>
            </Dropdown>
          </div >
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Outlet />
          <FloatButton shape="circle"
            type="primary" onClick={() => navigate(-1)} icon={<RollbackOutlined />} style={{ right: 94 }} />
        </Content>
      </Layout>
    </Layout>
    </ConfigProvider>
  );
};

export default Root;