import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import CompanyCode from "../entity/CompanyCode";
import UserData from "../entity/UserData";
import Role from "../entity/Role";
import Canteens from "../entity/Canteens";
import User from "../entity/User";
type MyMessageType = typeof message;
class ApiCanteens {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("canteens", message, navigate)
  }
  add(canteen:Canteens, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(canteen)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  update(canteen:Canteens, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(canteen)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
  getById(groupId:number,resultParam?: ResultParam<Canteens[]>) {
    this.internetTool.ft(
      `/group/${groupId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getUser(id:number,resultParam?: ResultParam<User>) {
    this.internetTool.ft(
      `/user/${id}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getByUId(uid:number,resultParam?: ResultParam<Canteens>) {
    this.internetTool.ft(
      `/uid/${uid}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  addUser(canteensId:number,userId?:number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    if(userId){
      formData.append("userId", userId.toString())
    }
    formData.append("canteensId", canteensId.toString())
    this.internetTool.ft(
      "/bind",
      {
        body:formData ,
        method: "POST"
      },
      resultParam,
    )
  }
}
export { ApiCanteens  }