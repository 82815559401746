import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import ExpertDrawData from "../entity/ExpertDrawData";
import Company from "../entity/Company";
import CompanyCode from "../entity/CompanyCode";
import UserData from "../entity/UserData";
import Role from "../entity/Role";
import Permission from "../entity/Permission";
type MyMessageType = typeof message;
class ApiAdmin {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("admin", message, navigate)
  }



  generateCode(delay_hours: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("delay_hours", delay_hours.toString())
    this.internetTool.ft(
      "/company_code",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  expertAdd(draw: ExpertDrawData, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("draw", new Blob([JSON.stringify(draw)], { type: "application/json" }))
    this.internetTool.ft(
      "/adraw",
      {
        body: formData,
        method: "POST"
      },
      resultParam
    )
  }
  delete(projectId: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("projectId", projectId.toString())
    this.internetTool.ft(
      ``,
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
  deleteUser(uid: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("uid", uid.toString())
    this.internetTool.ft(
      `/user`,
      {
        body: formData,
        method: "DELETE"
      },
      resultParam
    )
  }
  update(company_name: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("company_name", company_name)
    this.internetTool.ft(
      "/company",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateReplyTime(minute: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("minute", minute.toString())
    this.internetTool.ft(
      "/time",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateUser(user: UserData, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    this.internetTool.ft(
      "/user",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  getReplyTime(resultParam?: ResultParam<number>) {
    this.internetTool.ft(
      `/time`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  get(resultParam?: ResultParam<Company>) {
    this.internetTool.ft(
      ``,
      {
        method: "GET"
      },
      resultParam
    )
  }
  selectMessageCount(resultParam?: ResultParam<number>) {
    this.internetTool.ft(
      `/message_count`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getUserList(resultParam?: ResultParam<UserData[]>) {
    this.internetTool.ft(
      `/user`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getRole(resultParam?: ResultParam<Role[]>) {
    this.internetTool.ft(
      `/role`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getCode(resultParam?: ResultParam<CompanyCode>) {
    this.internetTool.ft(
      `/company_code`,
      {
        method: "GET"
      },
      resultParam
    )
  }

}
export default ApiAdmin