import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Radio, Select, Table, Tag, Upload, UploadProps, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { useNavigate } from 'react-router';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import ApiRole from '../../../internet/ApiRole';
import Role from '../../../entity/Role';
import ApiUser from '../../../internet/ApiUser';
import User from '../../../entity/User';
import Group from '../../../entity/Group';
import { UploadOutlined } from '@ant-design/icons';

const { Search } = Input;

const UserListPage: React.FC = () => {
  const [userDataState, setUserData] = useState<User[]>([]);
  const [originalUserDataState, setOriginalUserDataState] = useState<User[]>([]);
  const [userInfo, setUserInfo] = useState<User|null>();
  const [roleListState, setRoleList] = useState<Role[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm<User>();
  const [searchText, setSearchText] = useState<string>("");
  const uid=localStorage.getItem("userId")
  const user= localStorage.getItem("user") as string
  const navigate=useNavigate()
  const apiRole=new ApiRole(message,navigate)
  const apiUser=new ApiUser(message,navigate)
  const passReg = new RegExp(/^(\w){6,20}$/)
  const [fileList, setFileList] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const showModal = (users?:User) => {
    setUserInfo(users || null);
    setIsModalOpen(true);
    if(users){
      const roleId=users.role?.id
      form.setFieldsValue(users);
      form.setFieldValue("roleId",users.roleId)
    }else{
      form.resetFields();
    }
  };
  const handleOk = () => {
    if(userInfo){
      form.validateFields().then(data => {
        const role=roleListState.filter(i=>i.id===data.roleId)[0]
         const userData=new User(data.username,userInfo.password,userInfo.id,data.name,data.phone,data.roleId,data.status,data.gender,undefined,userInfo.createTime,undefined,role)
          // TODO: 调用 API 更新用户信息
          console.log(userData);
          apiUser.update(userData,{exit:0,successHandler:data=>{
            setIsModalOpen(false);
            getUserList()
            message.success("用户信息已更新")
          },otherHandler(code, msg) {
            return false
          },})

      }).catch(() => {});
    }else{
      form.validateFields().then(data => {
        const newUser=JSON.parse(user) as User
        const group=newUser.group
        const role=roleListState.filter(i=>i.id===data.roleId)[0]
         const userData=new User(data.username,data.password,0,data.name,data.phone,data.roleId,data.status,data.gender,undefined,new Date(),undefined,role,group)
          // TODO: 调用 API 更新用户信息
          apiUser.add(userData,{exit:0,successHandler:data=>{
            setIsModalOpen(false);
            getUserList()
            message.success("用户信息已更新")
          },otherHandler(code, msg) {
            return false
          },})

      }).catch(() => {});
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteUser = (id: number) => {
    // TODO: 调用 API 删除用户
   apiUser.delete(id,{exit:0,successHandler:()=>{
    getUserList()
    message.success("用户信息已更新")
   },otherHandler(code, msg) {
     return false
   },})
  };

  const columns: ColumnsType<User> = [
    {
      title: '角色',
      dataIndex: 'roleId',
      key: 'roleId',
      width: 100,
      ellipsis: true,
      render(value) {
        if(value){
          const text = roleListState.find(item => item.id === value)?.name;
          return <Tag color="geekblue-inverse">{text}</Tag>;
        }else{
          const text ="暂无角色"
         return <Tag color="geekblue-inverse">{text}</Tag>;
        }
      },
    },
    {
      title: '用户名',
      dataIndex: 'username',
      key: 'username',
      width: 100,
      ellipsis: true
    },
    {
      title: '手机号码',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      ellipsis: true
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      width: 80,
      ellipsis: true
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 150,
      ellipsis: true,
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: '操作',
      key: 'operation',
      render: (_, record) => (
        <div>
          <Button type="link" style={{ marginRight: 20 }} onClick={() => showModal(record)}>修改用户</Button>
          <Popconfirm title="是否删除该用户" onConfirm={() => deleteUser(record.id)}>
            <Button type="link" style={{color:"red"}}>删除用户</Button>
          </Popconfirm>
        </div>
      ),
      width: 150,
      ellipsis: true,
      align: "center"
    },
  ];

  const getRoleListById=()=>{
    apiRole.getById(Number(uid),{exit:0,successHandler:data=>{
      if(data){
        setRoleList(data)
      }
    }})
  }
  const getUserList=()=>{
    if(user){
      const newUser=JSON.parse(user) as User
      const groupId=newUser.group?.id
      if(groupId){
        apiUser.getById(groupId,{exit:0,successHandler:(data)=>{
          if(data){
            setUserData(data.filter(i=>i.id!== Number(uid)));
            setOriginalUserDataState(data.filter(i=>i.id!== Number(uid)))
          }
        },otherHandler(code, msg) {
          return false
        },})
      }
    }
  }
  useEffect(() => {
    getRoleListById()
    getUserList()
    // 初始化数据
  }, []);
  const options = roleListState&&roleListState.map(role => ({
      value: role.id,
      label: role.name,
    }));
 
  const onSearch = (e: any) => {
    setSearchText(e)
    const regex = new RegExp(e, 'i'); // 'i' 表示不区分大小写
    if (e !== "") {
      const filteredOrders = originalUserDataState.filter((userDatas) => {
        return (
          userDatas.name?.match(regex) ||
          userDatas?.phone?.toString().match(regex) ||
          userDatas.createTime?.toString().match(regex) 
        );
      });
      setUserData(filteredOrders);
    } else {
      setUserData(originalUserDataState);
    }
  };
  const handleUpload = () => {
    setUploading(true);
    if (fileList) {
      apiUser.uploadUser(fileList,{exit:0, successHandler: () => {
        setFileList(undefined);
       setUploading(false);
       getUserList()
       message.success("上传成功")
     },
     otherHandler(code, msg) {
        setUploading(false);
       message.error(code)
         return false
     },})

      
    }
  };
  const props: UploadProps = {
    onRemove: () => {
      setFileList(undefined);
    },
    beforeUpload: (file) => {
      setFileList(file);
      return false;
    },
    maxCount: 1,
    showUploadList:!!fileList,
    listType: "picture"
  };
  return (
    <div style={{ width: "100%", height: "100%", padding: "1%" }}>
      <Search style={{ width: "40%", float: "right", marginBottom: 10 }} placeholder="输入查询内容" onSearch={onSearch} enterButton />
      <Button type="primary" onClick={() => showModal()}>新增用户</Button>
      <Upload {...props} style={{ width: 200 }}>
      <Button style={{ marginLeft:10 }} icon={<UploadOutlined />}>批量上传</Button>
        </Upload>
        {
          fileList ?
            <Button
              type="primary"
              onClick={handleUpload}
              disabled={!fileList}
              loading={uploading}
              style={{ margin: 8 }}
            >
              {uploading ? '上传中' : '点击上传'}
            </Button> : <></>
        }
      <Table
        columns={columns}
        size="small"
        rowKey="id"
        pagination={{ pageSize: 7 }}
        dataSource={userDataState}
      />
      <Modal title="修改信息" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form} labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
        <Form.Item
          label="用户昵称"
          name="username"
          rules={[{ required: true, message: '请输入用户昵称' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="姓名"
          name="name"
        >
          <Input />
        </Form.Item>
        {
          !userInfo? 
          <div>
          <Form.Item
          label="密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入你的密码!',
            }, {
              pattern: passReg,
              message: "密码为6-20个字母、数字、下划线"
            }
          ]}
          hasFeedback
        >
          <Input.Password placeholder='请输入密码' />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请再次输入你的密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次输入的密码不一致!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='请确认密码'  />
        </Form.Item>
        </div>
        :<></>
        }
        <Form.Item
          label="手机号码"
          name="phone"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="用户性别"
          name="gender"
        >
          <Select>
            <Select.Option value={0}>男</Select.Option>
            <Select.Option value={1}>女</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="状态"
          name="status"
        >
          <Radio.Group>
            <Radio value={0}>正常</Radio>
            <Radio value={1}>停用</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="角色"
          name="roleId"
          rules={[{ required: true, message: '请输入角色' }]}
        >
          <Select  allowClear  options={options}/>
        </Form.Item>
      </Form>
      </Modal>
    </div>
  );
};

export default UserListPage;
