export default  class Permission {
    id: number;
    label?: string;
    parentId?: number;
    parentName?: string;
    code?: string;
    path?: string;
    name?: string;
    url?: string;
    type?: number; // 0-目录，1-菜单，2-按钮
    icon?: string;
    createTime?: Date;
    updateTime?: Date;
    remark?: string;
    orderNum?: number;
    isDeleted?: number; // 0-未删除，1-已删除
    open?: boolean;
    children?: Permission[];
    value?: string;
  
    constructor(
      id: number,
      label?: string,
      parentId?: number,
      parentName?: string,
      code?: string,
      path?: string,
      name?: string,
      url?: string,
      type?: number,
      icon?: string,
      createTime?: Date,
      updateTime?: Date,
      remark?: string,
      orderNum?: number,
      isDeleted?: number,
      open?: boolean,
      children?: Permission[],
      value?: string
    ) {
      this.id = id;
      this.label = label;
      this.parentId = parentId;
      this.parentName = parentName;
      this.code = code;
      this.path = path;
      this.name = name;
      this.url = url;
      this.type = type;
      this.icon = icon;
      this.createTime = createTime;
      this.updateTime = updateTime;
      this.remark = remark;
      this.orderNum = orderNum;
      this.isDeleted = isDeleted;
      this.open = open;
      this.children = children || [];
      this.value = value;
    }
  }