import Permission from "../entity/Permission";

export class PermissionUtils {
  // 收集所有权限的 ID
  static collectIds(permissions: Permission[]): number[] {
    let ids: number[] = [];

    const collect = (perms: Permission[]) => {
      perms.forEach(perm => {
        ids.push(perm.id!);
        if (perm.children && perm.children.length > 0) {
          collect(perm.children);
        }
      });
    };

    collect(permissions);
    return ids;
  }
 // 收集只有子权限的 ID
 static collectLeafIds(permissions: Permission[]): number[] {
  let ids: number[] = [];

  const collect = (perms: Permission[]) => {
    perms.forEach(perm => {
      if (!perm.children || perm.children.length === 0) {
        ids.push(perm.id!);
      } else {
        collect(perm.children);
      }
    });
  };

  collect(permissions);
  return ids;
}

  // 查找所有父级的 key
  static findParentKeys(menuData: Permission[], checkedKeys: number[]): number[] {
    const parentKeys: Set<number> = new Set();
    const findParents = (id: number, menuData: Permission[]) => {
      for (const item of menuData) {
        if (item.children && item.children.some(child => child.id === id)) {
          parentKeys.add(item.id!);
          findParents(item.id!, menuData);
        }
      }
    };
    checkedKeys.forEach(key => findParents(key, menuData));
    return Array.from(parentKeys);
  }

  // 过滤菜单数据
  static filterMenuData(menuData: Permission[], checkedKeys: number[]): Permission[] {
    const allKeys = new Set([...checkedKeys, ...this.findParentKeys(menuData, checkedKeys)]);
    return menuData.reduce((acc: Permission[], menu) => {
      if (allKeys.has(menu.id!)) {
        const newItem: Permission = { ...menu };
        if (menu.children) {
          newItem.children = this.filterMenuData(menu.children, checkedKeys);
        }
        acc.push(newItem);
      }
      return acc;
    }, []);
  }
  // 对权限进行排序并更新localStorage
  static sortPermissions(data: Permission[]): Permission[] {
    return data
      .map(permission => ({
        ...permission,
        children: permission.children ? this.sortPermissions(permission.children) : [],
      }))
      .sort((a, b) => {
        const aOrder = a.orderNum !== undefined && a.orderNum !== null && a.orderNum !== 0 ? a.orderNum : Number.MAX_SAFE_INTEGER;
        const bOrder = b.orderNum !== undefined && b.orderNum !== null && b.orderNum !== 0 ? b.orderNum : Number.MAX_SAFE_INTEGER;
        return aOrder - bOrder;
      });
  }

  // 对权限进行排序并更新localStorage
  static sortAndStorePermissions(data: Permission[]) {
    const sortedPermissions = this.sortPermissions(data);
    localStorage.setItem("routes", JSON.stringify(sortedPermissions));
  }
  
  // // 找出增加的权限和删除的权限，递归比较所有层级
  // static diffPermissions(currentPermissions: Permission[], newPermissions: Permission[]): { added: Permission[], removed: Permission[] } {
  //   const currentIds = new Set(this.collectLeafIds(currentPermissions));
  //   const newIds = new Set(this.collectLeafIds(newPermissions));

  //   const added: Permission[] = [];
  //   const removed: Permission[] = [];

  //   const comparePermissions = (current: Permission[], updated: Permission[]) => {
  //     const currentMap = new Map(current.map(perm => [perm.id, perm]));
  //     const updatedMap = new Map(updated.map(perm => [perm.id, perm]));

  //     updated.forEach(perm => {
  //       if (!currentMap.has(perm.id) && !added.some(p => p.id === perm.id)) {
  //         if (!perm.children || perm.children.every(child => newIds.has(child.id!))) {
  //           added.push(perm);
  //         } else {
  //           comparePermissions([], perm.children || []);
  //         }
  //       } else {
  //         comparePermissions(currentMap.get(perm.id!)?.children || [], perm.children || []);
  //       }
  //     });

  //     current.forEach(perm => {
  //       if (!updatedMap.has(perm.id) && !removed.some(p => p.id === perm.id)) {
  //         if (!perm.children || perm.children.every(child => currentIds.has(child.id!))) {
  //           removed.push(perm);
  //         } else {
  //           comparePermissions(perm.children || [], []);
  //         }
  //       }
  //     });
  //   };

  //   comparePermissions(currentPermissions, newPermissions);

  //   // 过滤掉父级权限，只保留叶子节点
  //   // const filterLeafPermissions = (permissions: Permission[], ids: Set<number>): Permission[] => {
  //   //   const result: Permission[] = [];
  //   //   const collectLeafPermissions = (perms: Permission[]) => {
  //   //     perms.forEach(perm => {
  //   //       if (!perm.children || perm.children.length === 0) {
  //   //         if (ids.has(perm.id!)) {
  //   //           result.push(perm);
  //   //         }
  //   //       } else {
  //   //         collectLeafPermissions(perm.children);
  //   //       }
  //   //     });
  //   //   };
  //   //   collectLeafPermissions(permissions);
  //   //   return result;
  //   // };
  //   return {
  //     added: added,
  //     removed: removed
  //   };
  // }
   // 找出增加的权限和删除的权限，递归比较所有层级
   static diffPermissions(currentPermissions: Permission[], newPermissions: Permission[]): { added: Permission[], removed: Permission[] } {
    const currentIds = new Set(this.collectLeafIds(currentPermissions));
    const newIds = new Set(this.collectLeafIds(newPermissions));
  
    const added: Permission[] = [];
    const removed: Permission[] = [];
  
    const comparePermissions = (current: Permission[], updated: Permission[], added: Permission[], removed: Permission[]) => {
      const currentMap = new Map(current.map(perm => [perm.id, perm]));
      const updatedMap = new Map(updated.map(perm => [perm.id, perm]));
  
      updated.forEach(perm => {
        if (!currentMap.has(perm.id)) {
          if (!perm.children || perm.children.every(child => newIds.has(child.id!))) {
            added.push(perm);
          } else {
            comparePermissions([], perm.children || [], added, removed);
          }
        } else {
          comparePermissions(currentMap.get(perm.id!)?.children || [], perm.children || [], added, removed);
        }
      });
  
      current.forEach(perm => {
        if (!updatedMap.has(perm.id)) {
          if (!perm.children || perm.children.every(child => currentIds.has(child.id!))) {
            removed.push(perm);
          } else {
            comparePermissions(perm.children || [], [], added, removed);
          }
        }
      });
    };
  
    comparePermissions(currentPermissions, newPermissions, added, removed);
  
    const ensureHierarchy = (permissions: Permission[], allPermissions: Permission[]): Permission[] => {
      const result: Permission[] = [];
      const map = new Map(permissions.map(perm => [perm.id, perm]));
  
      const buildHierarchy = (perm: Permission) => {
        if (perm.parentId) {
          const parent = allPermissions.find(p => p.id === perm.parentId);
          if (parent) {
            if (!map.has(parent.id!)) {
              map.set(parent.id!, { ...parent, children: [] });
              buildHierarchy(parent);
            }
            const parentPerm = map.get(parent.id!)!;
            if (!parentPerm.children) {
              parentPerm.children = [];
            }
            if (!parentPerm.children.some(child => child.id === perm.id)) {
              parentPerm.children.push(perm);
            }
          }
        } else {
          result.push(perm);
        }
      };
  
      permissions.forEach(buildHierarchy);
      return result;
    };
  
    const uniqueAdded = ensureHierarchy(added, newPermissions);
    const uniqueRemoved = ensureHierarchy(removed, currentPermissions);
  
    const filterActualChanges = (permissions: Permission[]): Permission[] => {
      const result: Permission[] = [];
      const traverse = (perms: Permission[]) => {
        perms.forEach(perm => {
          if (perm.children && perm.children.length > 0) {
            const filteredChildren = filterActualChanges(perm.children);
            if (filteredChildren.length > 0) {
              result.push({ ...perm, children: filteredChildren });
            }
          } else if (currentIds.has(perm.id!) !== newIds.has(perm.id!)) {
            result.push(perm);
          }
        });
      };
      traverse(permissions);
      return result;
    };
  
    return {
      added: filterActualChanges(uniqueAdded),
      removed: filterActualChanges(uniqueRemoved),
    };
  }

  
// 获取所有type为2的权限并平铺
static getFlatType2Permissions(): Permission[] {
  const flatPermissions: Permission[] = [];
  const routes= localStorage.getItem("routes") as string
  const data=JSON.parse(routes) as Permission[]
  const collectType2Permissions = (perms: Permission[]) => {
    perms?.forEach(perm => {
      if (perm.type === 2) {
        flatPermissions.push(perm);
      }
      if (perm.children && perm.children.length > 0) {
        collectType2Permissions(perm.children);
      }
    });
  };

  collectType2Permissions(data);
  return flatPermissions;
}
// 根据权限标识判断是否有权限
static hasPermission(code: string): boolean {
  const flatPermissions = this.getFlatType2Permissions();
  return flatPermissions.some(perm => perm.code === code);
}
}
