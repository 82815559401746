import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Tabs, TabsProps, message } from 'antd';
import ApiUser from '../../../internet/ApiUser';
import { useLocation, useNavigate } from 'react-router';
import User from '../../../entity/User';
import { useForm } from 'antd/es/form/Form';


const UserInfo: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const apiUser = new ApiUser(message, navigate)
  const [userInfo, setUserInfo] = useState<User>();
  const [form] = useForm<User>()
  const [passwordForm] = useForm()
  const passReg = new RegExp(/^(\w){6,20}$/)
  const uid=localStorage.getItem("userId")
  const getUser = () => {
    if(uid){
      apiUser.get(Number(uid),{
        exit: 0,
        successHandler: (user => {
          if (user) {
            setUserInfo(user)
            form.setFieldsValue(user)
          }
        }
        ), otherHandler: (code => {
          return true
        })
      })
    }
  }
  useEffect(() => {
    getUser()
  }, [location])
  const onChange = (key: string) => {
    console.log(key);
  };
  const updatePassword = () => {
    passwordForm.validateFields()
      .then(data => {
        console.log(data.password);
        apiUser.updatePassword(data.password, {
          exit: 0,
          successHandler: () => {
            message.success("修改成功")
            passwordForm.resetFields()
          }
        })
      }).catch(() => { })
  }
  const updateUserInfo = () => {
    form.validateFields()
      .then(data => {
        if (userInfo) {
          const info = userInfo
          info.username = data.username
          apiUser.updateRealName(info, {
            exit: 0,
            successHandler: () => {
              message.success("修改成功")
              passwordForm.resetFields()
            }
          })
        }
      }).catch(() => { })
  }
  const info: React.JSX.Element = <Form labelCol={{ span: 4 }}
    wrapperCol={{ span: 16 }} style={{ width: "30%" }} form={form}>
    <Form.Item
      label="用户名"
      name="username"
    >
      <Input readOnly bordered={false} />
    </Form.Item>
    <Form.Item
      label="真实姓名"
      name="name"
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="手机号"
      name="phone"
    >
      <Input readOnly bordered={false} />
    </Form.Item>
    <Form.Item

    >
      <Button type="primary" style={{ float: "right" }} onClick={updateUserInfo}>确认修改</Button>
    </Form.Item>
  </Form>
  const passwordInfo: React.JSX.Element = <Form form={passwordForm} labelCol={{ span: 4 }}
    wrapperCol={{ span: 16 }} style={{ width: "30%" }} >
    <Form.Item
      label="密码"
      name="password"
      rules={[
        {
          required: true,
          message: '请输入你的密码!',
        }, {
          pattern: passReg,
          message: "密码为6-20个字母、数字、下划线"
        }
      ]}
      hasFeedback
    >
      <Input.Password placeholder='请输入密码' />
    </Form.Item>
    <Form.Item
      label="确认密码"
      name="confirm"
      dependencies={['password']}
      hasFeedback
      rules={[
        {
          required: true,
          message: '请再次输入你的密码!',
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('两次输入的密码不一致!'));
          },
        }),
      ]}
    >
      <Input.Password placeholder='请确认密码' />
    </Form.Item>
    <Form.Item
    >
      <Button type="primary" style={{ float: "right" }} onClick={updatePassword}>确认修改</Button>
    </Form.Item>
  </Form>
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '基本信息',
      children: info,
    },
    {
      key: '2',
      label: '修改密码',
      children: passwordInfo,
    },

  ];
  return (
    <div style={{ width: "100%", height: "100%", position: "absolute" }}>
      <Tabs
        onChange={onChange}
        type="card"
        items={items}
      />
    </div>
  )
}

  ;

export default UserInfo;