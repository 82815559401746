import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import User from "../entity/User";
type MyMessageType = typeof message;
class ApiProjectSub {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("users", message, navigate)
  }
  add(users:User, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(users)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  uploadUser(file:File, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("file", file)
    this.internetTool.ft(
      "/upload",
      {
        body:formData,
        method: "POST"
      },
      resultParam
    )
  }
  getById(groupId:number,resultParam?: ResultParam<User[]>) {
    this.internetTool.ft(
      `/list/${groupId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  addProject(projectId: number, id: number, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("projectId", projectId.toString())
    formData.append("id", id.toString())
    this.internetTool.ft(
      "/project/role",
      {
        body: formData,
        method: "POST",
      },
      resultParam
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }


  update(users:User, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(users)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  updatePassword(password: string, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("password", password)
    this.internetTool.ft(
      `/password`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  updateRealName(user: User, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("user", new Blob([JSON.stringify(user)], { type: "application/json" }))
    this.internetTool.ft(
      `/realName`,
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }
  get(id:number,resultParam?: ResultParam<User>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "GET"
      },
      resultParam
    )
  }

  getAll(resultParam?: ResultParam<User[]>) {
    this.internetTool.ft(
      `/all`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getAllByGroup(resultParam?: ResultParam<User[]>) {
    this.internetTool.ft(
      `/allByGroup`,
      {
        method: "GET"
      },
      resultParam
    )
  }
 

  getByGroupId(resultParam?: ResultParam<User[]>) {
    this.internetTool.ft(
      `/groupId`,
      {
        method: "GET"
      },
      resultParam
    )
  }

}
export default ApiProjectSub