import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import CompanyCode from "../entity/CompanyCode";
import UserData from "../entity/UserData";
import Role from "../entity/Role";
import Group from "../entity/Group";
type MyMessageType = typeof message;
class ApiGroup {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("group", message, navigate)
  }
  add(group:Group, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(group)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  update(group:Group, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(group)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }

  getById(userId:number,resultParam?: ResultParam<Group>) {
    this.internetTool.ft(
      `/user?userId=${userId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  selectMessageCount(resultParam?: ResultParam<number>) {
    this.internetTool.ft(
      `/message_count`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getUserList(resultParam?: ResultParam<UserData[]>) {
    this.internetTool.ft(
      `/user`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  selectLogo(resultParam?: ResultParam<Uint8Array>) {
    this.internetTool.ft(
      `/logo`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getRole(resultParam?: ResultParam<Role[]>) {
    this.internetTool.ft(
      `/role`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  updateLogo(logo: File, resultParam?: ResultParam<void>) {
    let formData = new FormData()
    formData.append("logo", logo)
    this.internetTool.ft(
      "/logo",
      {
        body: formData,
        method: "PUT"
      },
      resultParam
    )
  }

  getCode(resultParam?: ResultParam<CompanyCode>) {
    this.internetTool.ft(
      `/company_code`,
      {
        method: "GET"
      },
      resultParam
    )
  }

}
export { ApiGroup }