import Permission from "./Permission";

 export default class Role {
  id: number;
  uid:number;
  name?: string;
  code?: string;
  status?: number;
  createdAt?: Date;
  description?: string;
  permissions?:Permission[]

  constructor(
    id: number,
    uid:number,
    name?: string,
    code?: string,
    status?: number,
    createdAt?: Date,
    description?: string,
    permissions?:Permission[]
  ) {
    this.id = id;
    this.uid = uid;
    this.name = name;
    this.code = code;
    this.status = status;
    this.createdAt = createdAt;
    this.description = description;
    this.permissions = permissions;
  }
}