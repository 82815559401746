import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RouterProvider,} from 'react-router';
import router from './Router';





function App() {

  return (
    <RouterProvider router={createBrowserRouter(router())} fallbackElement={<p>Loading...</p>} />
  )
}

export default App;
function disPatch(arg0: { type: string; data: import("./entity/Permission").default[]; }) {
  throw new Error('Function not implemented.');
}

