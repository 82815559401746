export default class Dish {
    id: number;
    name: string;
    price?: number;
    description?: string;
    categoryId?: number;
    imageBase64?: string;
    quantity?:number
    availability?: number;
  
    constructor(
      id: number,
      name: string,
      price?: number,
      description?: string,
      categoryId?: number,
      imageBase64?: string,
      quantity?:number,
      availability?: number
    ) {
      this.id = id;
      this.name = name;
      this.price = price;
      this.description = description;
      this.categoryId = categoryId;
      this.imageBase64 = imageBase64;
      this.quantity = quantity;
      this.availability = availability;
    }
  }