import { Button, Form, Input, Space, message, } from "antd";
import React, { useEffect, useState } from "react";
import Module from './StaffPage.module.scss'
import { useNavigate } from "react-router";
import User from "../../../entity/User";
import ApiOpen from "../../../internet/ApiOpen";
let timer: string | number | NodeJS.Timeout | undefined
const StaffPage: React.FC = () => {
  const passReg = new RegExp(/^(\w){6,20}$/)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [btnContent, setBtnContent] = useState('获取验证码')
  const [time, setTime] = useState(0)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const apiOpen = new ApiOpen(message, navigate)
  useEffect(() => {
    clearInterval(timer)
    return () => clearInterval(timer)
  }, [])
  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s后重发`)
    } else {
      clearInterval(timer)
      setBtnDisabled(false)
      setTime(60)
    }
  }, [time])
  const signUpMethod = () => {
    navigate("/sign-up")
  }
  const getCaptcha = () => {
    const mobilePhone = form.getFieldValue("mobilePhone") as string
    apiOpen.sendCode(mobilePhone, {
      exit: 0, successHandler: () => {
        timer = setInterval(() => setTime(pre => pre - 1), 1000)
        setBtnDisabled(true)
        message.success("发送成功")
      }, otherHandler(code, msg) {
        return true
      },
    })
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 6 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const onFinish = () => {
    form.validateFields().then(data => {
      const user = new User(data.username, data.mobilePhone, data.realName)
      const password = form.getFieldValue("password")
      const company_code = form.getFieldValue("company_code")
      const auth_code = form.getFieldValue("auth_code")
      apiOpen.signUpByCompany(user, password, company_code, auth_code, {
        exit: 0, successHandler: () => {
          form.resetFields()
          message.success("注册成功")
        }, otherHandler(code, msg) {
          return true
        },
      })

    }).catch(() => { })
  }
  return (
    <div className={Module.root}>
      <Form {...formItemLayout} size="small" form={form} className={Module.from}>
        <Form.Item
          name="company_code"
          label="公司注册码"
          rules={[{ required: true, message: '请输入公司注册码' }]} >
          <Input allowClear placeholder="请输入公司注册码" />
        </Form.Item>
        <Form.Item name="mobilePhone" label="手机号码"
          rules={[{ required: true, message: '请输入手机号码' }]} >
          <Input allowClear placeholder="请输入手机号码" />
        </Form.Item>
        <Form.Item
          name="auth_code"
          label="验证码"
          rules={[{ required: true, message: '请输入验证码' }]}
        >
          <Space.Compact style={{ width: '100%' }}>
            <Input placeholder="请输入验证码" />
            <Button type='primary' value={btnContent} disabled={btnDisabled} onClick={getCaptcha} > {!btnDisabled ? '获取验证码' : `${time}s后重发`}</Button>
          </Space.Compact>

        </Form.Item>
        <Form.Item
          name="username"
          label="用户名"
          rules={[{ required: true, message: '请输入用户名' }]} >
          <Input allowClear placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item
          label="密码"
          name="password"
          rules={[
            {
              required: true,
              message: '请输入你的密码!',
            }, {
              pattern: passReg,
              message: "密码为6-20个字母、数字、下划线"
            }
          ]}
          hasFeedback
        >
          <Input.Password placeholder='请输入密码' className={Module.input} />
        </Form.Item>
        <Form.Item
          label="确认密码"
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: '请再次输入你的密码!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('两次输入的密码不一致!'));
              },
            }),
          ]}
        >
          <Input.Password placeholder='请确认密码' className={Module.input} />
        </Form.Item>
        <Form.Item
          name="realName"
          label="真实姓名"
          rules={[{ required: true, message: '请输入真实姓名' }]} >
          <Input allowClear placeholder="请输入真实姓名" />
        </Form.Item>
        <div className={Module.btns}>
          <Button className={Module.btn} size="large" type="primary" onClick={onFinish}>
            注册
          </Button>
          <Button className={Module.btn} size="large" >
            取消
          </Button>
        </div>
      </Form>
    </div>
  )
}
export default StaffPage






