import lodash from "lodash"
import ResultBody from "../entity/ResultBody"
import { ResultHandler } from "../tool/ResultHandler";
import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
type MessageApi = typeof message;
class InternetTool {
  private readonly baseUrl: string;
  static readonly success: string = "success";
  readonly url: string
  readonly resultHandler: ResultHandler

  constructor(url: string, message: MessageApi, navigate: NavigateFunction) {
    const domain = window.location.hostname;
    const port = window.location.port
    if (domain === "localhost") {
      this.baseUrl = `http://192.168.0.107:8081/api/`
    } else {
      if (port !== "80") {
        this.baseUrl = `https://${domain}:${port}/api/`
      } else {
        this.baseUrl = `https://${domain}/api/`
      }
    }
    this.url = url
    this.resultHandler = new ResultHandler(message, navigate)
  }

  ft<T>(url: string | URLSearchParams = "", init: RequestInit, resultParam: ResultParam<T> | undefined,json?:boolean) {
    let requestInit = lodash.cloneDeep(init)
    requestInit.credentials = "include"
    requestInit.mode = "cors"
    const satoken = localStorage.getItem("satoken");
    if (satoken) {
      if(json){
        requestInit.headers = {
          satoken: `${satoken}`,
         "Content-Type":"application/json"
        };
      }else{
        requestInit.headers = {
          satoken: `${satoken}`,
        };
      }
   
    }

    let fullUrl = this.baseUrl + this.url + url
    const param = resultParam ?? {}
    if (param.exit === undefined && (!init.method || init.method === "GET")) {
      param.exit = 0
    }
    fetch(fullUrl, requestInit)
      .then(response => {
        if (!response.ok) {
          console.log(response)
        }
        return response.text()
      }).then(text => {
        try {
          return JSON.parse(text, resultParam?.reviver) as ResultBody<T>
        } catch (error) {
          return new ResultBody<T>("json_error")
        }
      }
      ).then(this.resultHandler.bodyHandlerDefault(param))
  }

  ftopen<T>(url: string | URLSearchParams = "", init: RequestInit, resultParam: ResultParam<T> | undefined) {
    let requestInit = lodash.cloneDeep(init)
    requestInit.credentials = "include"
    requestInit.mode = "cors"
    let fullUrl = this.baseUrl + this.url + url
    const param = resultParam ?? {}
    if (param.exit === undefined && (!init.method || init.method === "GET")) {
      param.exit = 0
    }
    fetch(fullUrl, requestInit)
      .then(response => {
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        return response.text();
      }).then(text => {
        // 如果返回的文本为空，则返回一个指定字符串
        if (!text) {
          return new ResultBody<T>("success"); // 返回一个字符串而不是尝试解析它
        }
        try {
          return JSON.parse(text, resultParam?.reviver) as ResultBody<T>
        } catch (error) {
          return new ResultBody<T>("json_error")
        }
      }).then(this.resultHandler.bodyHandlerDefault(param))
      .catch(error => {
        // Handle any errors here
        console.error('Error:', error);
      });
  }

  fe<T>(url: string = "", resultParam: ResultParam<T> | undefined) {
    this.ft<T>(url, {}, resultParam)
  }
}

export default InternetTool
