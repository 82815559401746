import message from "antd/es/message";
import { NavigateFunction } from "react-router-dom";
import { ResultParam } from "../tool/ResultParam";
import InternetTool from "./Internetool";
import CompanyCode from "../entity/CompanyCode";
import UserData from "../entity/UserData";
import Role from "../entity/Role";
import Canteens from "../entity/Canteens";
import User from "../entity/User";
import Orders from "../entity/Oder";
type MyMessageType = typeof message;
class ApiOrder {
  readonly internetTool: InternetTool
  constructor(message: MyMessageType, navigate: NavigateFunction) {
    this.internetTool = new InternetTool("orders", message, navigate)
  }
  add(canteen:Canteens, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(canteen)
    this.internetTool.ft(
      "",
      {
        body:permissionList ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
  update(canteen:Canteens, resultParam?: ResultParam<void>) {
    const permissionList=JSON.stringify(canteen)
    this.internetTool.ft(
      "",
      {
        body: permissionList,
        method: "PUT"
      },
      resultParam,
      true
    )
  }
  delete(id: number, resultParam?: ResultParam<void>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "DELETE"
      },
      resultParam
    )
  }
 
  getUser(userId:number,statusId:number,resultParam?: ResultParam<Orders[]>) {
    this.internetTool.ft(
      `/user/${userId}/orders?statusId=${statusId}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  get(id:number,resultParam?: ResultParam<Orders>) {
    this.internetTool.ft(
      `/${id}`,
      {
        method: "GET"
      },
      resultParam
    )
  }
  getOderList(orderList:Orders[],resultParam?: ResultParam<Uint8Array>) {
    let formData = new FormData()
    formData.append("orderList", new Blob([JSON.stringify(orderList)], { type: "application/json" }))
    const orderList1=JSON.stringify(orderList)
    this.internetTool.ft(
      `/statistics`,
      {
        body:orderList1 ,
        method: "POST"
      },
      resultParam,
      true
    )
  }
 
}
export { ApiOrder  }