import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Popconfirm, Radio, Select, Switch, Table, Tag, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Group from '../../../entity/Group';
import { ApiGroup } from '../../../internet/ApiGroup';
import { useNavigate } from 'react-router';

const { Search } = Input;

const GroupInfoPage: React.FC = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [currentGroup, setCurrentGroup] = useState<Group | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm<Group>();
  const navigate=useNavigate()
  const apiGroup=new  ApiGroup(message,navigate)
  const uid=localStorage.getItem("userId")
  const fetchGroups = () => {
    apiGroup.getById(Number(uid),{exit:0,successHandler:data=>{
      if(data){
        setGroups([data])
      }
    }})
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const showModal = (group?: Group) => {
    setCurrentGroup(group || null);
    setIsModalOpen(true);
    if (group) {
      form.setFieldsValue(group);
    } else {
      form.resetFields();
    }
  };

  const handleOk = () => {
    form.validateFields().then(data => {
      if (currentGroup) {
        // Update group
          const updatedGroups = new Group(currentGroup.id, Number(uid), data.name, data.description, currentGroup.groupName, data.status);
          console.log(updatedGroups);
          apiGroup.update(updatedGroups,{exit:0,successHandler:()=>{
            setIsModalOpen(false);
             message.success('公司信息已更新');
             fetchGroups();
           }})
      } else {
        // Add new group
        const newGroup = new Group(0,Number(uid),data.name, data.description, data.groupName);
        apiGroup.add(newGroup,{exit:0,successHandler:()=>{
         setIsModalOpen(false);
          message.success('公司信息已更新');
          fetchGroups();
        }})
      }
    }).catch(() => {});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteGroup = (id: number) => {
    apiGroup.delete(id,{exit:0,successHandler:()=>{
      setIsModalOpen(false);
       message.success('公司信息已更新');
     }})
  };

  const columns: ColumnsType<Group> = [
    // {
    //   title: 'ID',
    //   dataIndex: 'id',
    //   key: 'id',
    //   width: 100,
    //   ellipsis: true
    // },
    {
      title: '公司名称',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      ellipsis: true
    },
    {
      title: '公司描述',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      ellipsis: true
    },
    {
      title: '分组名称',
      dataIndex: 'groupName',
      key: 'groupName',
      width: 200,
      ellipsis: true
    },
    {
      title: '操作',
      key: 'actions',
      width: 200,
      ellipsis: true,
      render: (_, record) => (
        <div>
          <Button type="primary" onClick={() => showModal(record)} style={{ marginRight: 8 }}>公司详情</Button>
          <Popconfirm title="Are you sure?" onConfirm={() => deleteGroup(record.id)}>
            <Button type="default">删除公司</Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      {
        groups.length<1?
      <Button type="primary" onClick={() => showModal()} style={{ marginBottom: 16 }}>添加公司</Button>:<></>
      }
      <Table size="small" columns={columns} dataSource={groups} rowKey="id" />
      <Modal title={currentGroup ? '修改公司' : '添加公司'} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Form form={form}labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}>
          <Form.Item name="name" label="公司名称" rules={[{ required: true, message: '请输入公司名称!' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="公司描述">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default GroupInfoPage;
