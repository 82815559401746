import { Button, Form, Input, Tabs, TabsProps, } from "antd";
import React from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Module from './RegisterPage.module.scss'
import { useNavigate } from "react-router";
import CompanyRegisterPage from "./company/CompanyRegisterPage";
import StaffPage from "./staff/StaffPage";

const RegisterPage: React.FC = () => {
  const passReg = new RegExp(/^(\w){6,20}$/)
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '公司注册',
      children: <CompanyRegisterPage />,
    },
    {
      key: '2',
      label: '员工注册',
      children: <StaffPage />,
    },

  ];
  return (
    <div className={Module.root}>
      <Form form={form} className={Module.from}>
        <h1 className={Module.h1} >用户注册</h1>
        <Form.Item>
          <Tabs defaultActiveKey="1" items={items} />
        </Form.Item>
      </Form>
    </div>
  )
}
export default RegisterPage






